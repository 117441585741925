<!-- begin:: Page -->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
	<!-- begin:: Header Mobile -->
	<kt-header-mobile></kt-header-mobile>
	<!-- end:: Header Mobile -->

	<div class="d-flex flex-column flex-root h-100">
		<!-- begin::Body -->
		<div class="d-flex flex-row flex-column-fluid page">
			<!-- begin:: Aside Left -->
			<ng-container *ngIf="asideDisplay">
				<button class="kt-aside-close" id="kt_aside_close_btn"><i class="la la-close"></i></button>
				<kt-aside-left></kt-aside-left>
			</ng-container>
			<!-- end:: Aside Left -->

			<div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
				<!-- begin:: Header -->
				<kt-header *ngIf="desktopHeaderDisplay"></kt-header>
				<!-- end:: Header -->
				<!-- begin:: Content -->
				<div class="content d-flex flex-column flex-column-fluid" id="kt_content" [ngClass]="contentClasses">
					<!-- begin:: Content Head -->
				
					<!-- end:: Content Head -->

					<!-- begin:: Content Body -->
					<div ktContentAnimate class="kt-container kt-grid__item kt-grid__item--fluid" [ngClass]="{'kt-container--fluid': fluid}">
						<router-outlet></router-outlet>
					</div>
					<!-- end:: Content Body -->
				</div>
				<!-- end:: Content -->

				<kt-footer></kt-footer>
			</div>
		</div>
		<!-- end:: Body -->

		<kt-quick-panel></kt-quick-panel>
		<kt-scroll-top></kt-scroll-top>
	</div>
</ng-container>
<!-- end:: Page -->

<ng-template #blankLayout>
	<router-outlet></router-outlet>
</ng-template>
