// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, UserRequested, UserLoaded } from '../_actions/auth.actions';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { User } from '../_models/user.model';
import { AuthenticationService } from '../../../views/pages/auth/login/login.service';

@Injectable()
export class AuthEffects {
    @Effect({dispatch: false})
    login$ = this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap(action => {
            localStorage.setItem(environment.authTokenKey, action.payload.access_token)

            const user = new User();
            user.username = action.payload.username;
            user.avatar = action.payload.avatar;

            this.store.dispatch(new UserLoaded( {user} ));
        }),
    );

    @Effect({dispatch: false})
    logout$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {

            this.auth.logout().subscribe(response => {
                localStorage.removeItem(environment.authTokenKey);
                localStorage.removeItem('avatar');
                localStorage.removeItem('username');
                this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.returnUrl}});
            })

            
        })
    );


    @Effect({dispatch: false})
    loadUser$ = this.actions$.pipe();

    @Effect()
    init$: Observable<Action> = defer(() => {
        const access_token = localStorage.getItem(environment.authTokenKey);
        const username = localStorage.getItem('username');
        const avatar = localStorage.getItem('avatar');
        

        let observableResult = of({type: 'NO_ACTION'});
        if (access_token) {
            observableResult = of(new Login({  access_token, username, avatar }));
        }
        return observableResult;
    });

    private returnUrl: string;

    constructor(private actions$: Actions,
        private router: Router,
        private auth:  AuthenticationService,
        private store: Store<AppState>) {

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.returnUrl = event.url;
			}
		});
	}
}
