import { ActivatedRouteSnapshot, CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import Swal from 'sweetalert2';
import { from } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

    public confirmationFlag: boolean;

    confirmation(): Observable<boolean>{
        // Confirmation 1
        return from(
            Swal.fire({
                title: 'You have unsaved changes! Are you sure you want to leave?',
                showCancelButton: true,
                confirmButtonColor: '#5867dd',
                cancelButtonColor: '#868e96',
                confirmButtonText: 'Leave Page',
                cancelButtonText: 'Stay on Page'
            }).then((result1) => {
                if (result1.value) {
                    // Confirmation 2
                    return Swal.fire({
                        title: 'If you leave now, your company in onboarding will not be saved!  Please add Company Information before exiting.',
                        showCancelButton: true,
                        confirmButtonColor: '#5867dd',
                        cancelButtonColor: '#868e96',
                        confirmButtonText: 'Leave Page',
                        cancelButtonText: 'Stay on Page'
                    }).then((result2) => {
                        if (result2.value) {
                            return result2.value;
                        }else{
                            return result2.value;
                        }
                    });
                }else{
                    return result1.value;
                }
            })
        );
    }

    canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot): boolean | Observable<boolean> {
        // if there are no pending changes, just allow deactivation; else confirm first
        return component.canDeactivate() ? true : this.confirmation()
    }
}