export class PageConfig {
    public defaults: any = {
        dashboard: {
            page: {
                title: "Dashboard",
                desc: "Latest updates and statistic charts",
            },
        },
        companies: {
            page: {
                title: "Companies",
                desc: "",
            },
        },
        industries: {
            page: {
                title: "Industries",
                desc: "",
            },
        },
        users: {
            page: {
                title: "Users",
                desc: "",
            },
        },
        transactions: {
            page: {
                title: "Transactions",
                desc: "",
            },
        },
        "monthly-payments": {
            page: {
                title: "Monthly payments",
                desc: "",
            },
        },
        withdrawals: {
            page: {
                title: "Withdrawals",
                desc: "",
            },
        },
        "referral-bonus": {
            page: {
                title: "Withdrawal of Referral Bonus",
                desc: "",
            },
        },
    };

    public get configs(): any {
        return this.defaults;
    }
}
