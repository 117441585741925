export const routes = {
    auction: {
        //v1.1
        create: "v1.1/auction/create",
        update: "v1.1/auction/",
        updateStatus: "v1.1/auction/update/status",
        secondReconfirmation: "v1.1/auction/second-reconfirmation",
        thirdReconfirmation: "v1.1/auction/third-reconfirmation",
        overview: "v1.1/auction/overview",
        progress: "v1.1/auction/progress",
        details: "v1.1/auction/details",
        refundAfterSettlement: "v1.1/auction/refund-orders-after-settlement",
        //v1.0
        resendReconfirmationEmails: "v1.0/auction/resend-reconfirmation-emails",
    },
    bid: {
        //v1.1
        create: "v1.1/bid/create",
        cancel: "v1.1/bid/cancel",
        //v1.0
        placedBids: "v1.1/placed-bids",
        resendEmail: "v1.0/bid/resend-email",
        edit: "v1.0/bid/edit",
        resubmit: "v1.0/bid/resubmit",
        regenerateBondCertificate: "v1.0/bid/regenerate-bond-certificate",
    },
    bonds: {
        send: "v1.0/bonds/send",
        transfer: "v1.0/bonds/",
        viewTransfer: "v1.1/payments/transfer/formated-details/",
        testSend: "v1.0/bonds/test/send",
    },
    company: {
        ////////////// NEW ENDPOINTS //////////////
        base: "v1.1/company", //Base route
        create: "v1.1/company",
        addDocument: "v1.1/company",
        deleteDocument: "v1.1/company",
        //images
        imagesByType: "v1.1/company",
        images: "v1.1/company/images",
        setPrimaryImage: "v1.1/company/images/set_primary",
        reorderImages: "v1.1/company/images/reorder",
        //Videos
        updateVideos: "v1.1/company",
        //Fund uses
        addUseFunds: "v1.1/company/add-fund-uses",
        updateUseFunds: "v1.1/company/update-fund-uses",
        //Founders
        founders: "v1.1/company/founders",
        //Business plan
        addBusinessPlan: "v1.1/company/overview/save-futureplan",
        updateBusinessPlan: "v1.1/company/overview/update-futureplan",
        ///////////////////////////////////////////

        list: "v1.0/company/list",
        update: "v1.0/company/update",
        overview: "v1.0/company/overview",
        disclosures: "v1.0/company/disclosures",
        updateBanner: "v1.0/company/update-banner",
        //team
        team: "v1.0/company/team",
        addTeam: "v1.0/company/add-team",
        deleteTeam: "v1.0/company/delete-team",
        editTeam: "v1.0/company/edit-team",
        //investor
        investor: "v1.0/company/investor",
        addInvestor: "v1.0/company/add-investor",
        deleteInvestor: "v1.0/company/delete-investor",
        editInvestor: "v1.0/company/edit-investor",
        //news
        news: "v1.0/company/news",
        addNews: "v1.0/company/add-news",
        deleteNews: "v1.0/company/delete-news",
        editNews: "v1.0/company/edit-news",
        //financial
        financialInformation: "v1.0/company/financialInformation",
        addFinancial: "v1.0/company/add-financial",
        deleteFinancial: "v1.0/company/delete-financial",
        //documents
        document: "v1.0/company/document",
    },
    faq: {
        unique: "v1.0/faq",
        multipleDelete: "v1.0/faq/multiple/delete",
        import: "v1.0/faq/import",
    },
    industry: {
        unique: "v1.0/industry",
        list: "v1.0/industry/list",
        create: "v1.0/industry/create",
    },
    issuerOnboardings: {
        onboardingEmail: "v1.1/issuer_onboardings",
        create: "v1.1/issuer_onboardings",
        company: "v1.1/issuer_onboardings",
        documents: "v1.1/issuer_onboardings/documents",
        open: "v1.1/issuer_onboardings/list/open",
        closed: "v1.1/issuer_onboardings/list/closed",
        createCSV: "v1.1/issuer_onboardings/csv",
    },
    location: {
        list: "v1.0/location/list",
    },
    login: "v1.0/login",
    logout: "v1.0/logout",
    market: {
        dashboard: "v1.1/market/dashboard",
        offerings: "admin/offerings",
    },
    monthly: {
        payments: "v1.0/monthlypayments",
        paymentsByOffering: "v1.1/admin/payments",
        issuer: "v1.0/monthlypayments/issuer",
    },
    offering: {
        status: "v1.0/offering/status",
        sync: "v1.0/offering/sync/",
        about: "v1.0/offering/about",
        comments: "v1.0/offering/comments",
    },
    payments: {
        transactions: "v1.0/payments/transactions",
        transactionsExcel: "v1.1/payments/transactions",
        updatePartialRefund: "v1.0/payments/updatePartialRefund",
        withdrawal: "v1.0/payments/withdrawal",
        updateWithdrawals: "v1.0/payments/updateWithdrawals",
        withdrawalExport: "v1.0/payments/withdrawal/export",
        monthlyIssuer: "v1.0/payments/monthlyIssuer",
        withdrawlsInvestor: "v1.0/payments/withdrawalsInvestor",
        totalRemaining: "v1.0/payments/totalRemaining",
        auctionLastPayment: "v1.0/payments/auctionLastPayment",
        complete: "admin/payments/complete",
    },
    referralBonus: {
        base: "admin/referral_codes",
        accounts: "admin/referral_codes_accounts",
        withdrawals: "admin/referral_codes_withdrawals",
        accept: "admin/referral_codes_accept_withdrawal",
        acceptBatch: "/admin/referral_codes_accept_batch_withdrawal",
    },
    portfolio: {
        walletSummary: "v1.1/portfolio/walletsummary",
    },
    resetPasswordWeb: "v1.0/resetPassword/web",
    resetPassword: "v1.0/resetPassword",
    security: {
        unique: "v1.0/security",
        list: "v1.0/security/list",
        create: "v1.0/security/create",
        snos: "v1.0/security/snos",
        all: "v1.0/security/all",
    },
    states: "v1.0/states",
    testEmails: "v1.0/test/emails",
    user: {
        //v1.1
        amounts: "v1.1/user/amounts",
        getUser: "v1.1/user/getuser",
        investor: "v1.1/user/investor",
        docVerified: "v1.1/user/entity-doc-verified",
        filterList: "v1.1/users/investor/list/basic-filter",
        //v1.0
        addCredit: "v1.0/user/add-credit",
        bidsList: "v1.0/user/bids-list",
        checkStatus: "v1.0/user/checkStatus",
        create: "v1.0/user/create",
        delete: "v1.0/user/delete",
        export: "v1.0/user/export",
        failedEmails: "v1.0/user/failed-emails",
        kycaml: "v1.0/user/kycaml",
        list: "v1.0/user/list",
        postUser: "v1.0/user/postuser",
        resendWelcome: "v1.0/user/resend-welcome",
        watchlistUpdate: "v1.0/user/watchlist/update",
    },
};
