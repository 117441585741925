import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import LogRocket from "logrocket";
import { Observable } from "rxjs";
import { delay, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../api.service";

@Injectable()
export class EnvResolver implements Resolve<any> {
    constructor(private service: ApiService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this.service
            .getRaw(
                `${window.location.protocol}//${window.location.host}/apiendpoint.json`
            )
            .pipe(
                map((response) => {
                    environment.server_url = response.serverAPIURL;
                    environment.server_api = `${response.serverAPIURL}/api/`;
                    environment.server_webapi = `${response.serverAPIURL}/webapi/`;
                    environment.logRocket_id = response.logRocketID;

                    if (environment.logRocket_id !== "") {
                        // Use LogRocket only in prod app
                        if (
                            !(
                                window.location.host.includes("dev") ||
                                window.location.host.includes("localhost") ||
                                window.location.host.includes("demo")
                            )
                        ) {
                            console.log("Initialize LogRocket");
                            // Initialize LogRocket with app ID
                            LogRocket.init(environment.logRocket_id, {
                                dom: {
                                    isEnabled: false,
                                },
                            });
                        }
                    }
                })
            );
    }
}
