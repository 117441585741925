// SERVICES
export { AuthNoticeService } from './auth-notice/auth-notice.service';



// ACTIONS
export {
    Login,
    Logout,
    UserRequested,
    UserLoaded,
    AuthActionTypes,
    AuthActions,
    Register,
} from './_actions/auth.actions';


// EFFECTS
export { AuthEffects } from './_effects/auth.effects';
export { AuthService} from './_services/auth.service';
// REDUCERS
export { authReducer } from './_reducers/auth.reducers';

// SELECTORS
export {
    isLoggedIn,
    isLoggedOut,
    isUserLoaded,
    currentAuthToken,
    currentUser,
} from './_selectors/auth.selectors';


// GUARDS
export { AuthGuard } from './_guards/auth.guard';
export { ModuleGuard } from './_guards/module.guard';

// MODELS
export { User } from './_models/user.model';

export { Role } from './_models/role.model';
export { Address } from './_models/address.model';
export { SocialNetworks } from './_models/social-networks.model';
export { AuthNotice } from './auth-notice/auth-notice.interface';

export { AuthDataContext } from './_server/auth.data-context';
