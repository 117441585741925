import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../environments/environment";

@Injectable()
export class ApiService {
    constructor(private http: HttpClient) {}
    //TODO: DRY
    get(url: string, options = {}): Observable<any> {
        const server = environment.server_api;

        let opt: any = { ...options };
        const params = new HttpParams().set("platform", "adminPanel");
        opt.params = params;

        if (localStorage.getItem("token")) {
            opt.headers = opt.headers || {};
            opt.headers["Authorization"] = `bearer ${localStorage.getItem(
                "token"
            )}`;
            opt.headers["Content-Type"] = "application/json";
            opt.headers["Access-Control-Allow-Origin"] = "*";
            opt.headers["responseType"] = "text";
        }

        return this.http.get(`${server}${url}`, opt).pipe(
            tap((_) => {}),
            catchError(this.handleError(url, null))
        );
    }

    post(
        url: string,
        body: FormData | any,
        segment = "api",
        options = {}
    ): Observable<any> {
        const server =
            segment === "api"
                ? environment.server_api
                : environment.server_webapi;
        let opt: any = { ...options };
        const params = new HttpParams().set("platform", "adminPanel");
        opt.params = params;
        if (localStorage.getItem("token")) {
            opt.headers = opt.headers || {};
            opt.headers["Authorization"] = `bearer ${localStorage.getItem(
                "token"
            )}`;
        }

        return this.http.post(`${server}${url}`, body, opt).pipe(
            tap((_) => {}),
            catchError(this.handleError(url, null))
        );
    }
    patch(
        url: string,
        body: FormData | any,
        segment = "api",
        options = {}
    ): Observable<any> {
        const server =
            segment === "api"
                ? environment.server_api
                : environment.server_webapi;
        let opt: any = { ...options };
        const params = new HttpParams().set("platform", "adminPanel");
        opt.params = params;
        if (localStorage.getItem("token")) {
            opt.headers = opt.headers || {};
            opt.headers["Authorization"] = `bearer ${localStorage.getItem(
                "token"
            )}`;
        }

        return this.http.patch(`${server}${url}`, body, opt).pipe(
            tap((_) => {}),
            catchError(this.handleError(url, null))
        );
    }
    put(url: string, body, segment = "api"): Observable<any> {
        const server =
            segment === "api"
                ? environment.server_api
                : environment.server_webapi;
        let headers = {};
        const params = new HttpParams().set("platform", "adminPanel");
        if (localStorage.getItem("token")) {
            headers = {
                Authorization: `bearer ${localStorage.getItem("token")}`,
            };
        }
        return this.http.put(`${server}${url}`, body, { headers, params }).pipe(
            tap((_) => {}),
            catchError(this.handleError(url, null))
        );
    }
    delete(
        url: string,
        body = new FormData(),
        segment = "api"
    ): Observable<any> {
        const server =
            segment === "api"
                ? environment.server_api
                : environment.server_webapi;
        let headers = {};
        const params = new HttpParams().set("platform", "adminPanel");
        if (localStorage.getItem("token")) {
            headers = {
                Authorization: `bearer ${localStorage.getItem("token")}`,
            };
        }

        return this.http.request("delete", `${server}${url}`, {
            headers,
            body,
            params,
        });
    }

    getRaw(url: string, options = {}): Observable<any> {
        const server = url;
        let opt: any = { ...options };
        if (localStorage.getItem("token")) {
            opt.headers = opt.headers || {};
            opt.headers["Authorization"] = `bearer ${localStorage.getItem(
                "token"
            )}`;
        }

        return this.http.get(`${server}`, opt).pipe(
            tap((_) => {}),
            catchError(this.handleError(url, null))
        );
    }

    downLoadFile(
        name: string,
        data: any,
        type: string,
        format: string = ".xlsx"
    ) {
        let blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = url;
        a.download = name + format;
        a.click();
        window.URL.revokeObjectURL(url);

        // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        //     alert( 'Please disable your Pop-up blocker and try again.');
        // }
    }
    serialize(obj: any) {
        let notEmptyObj = {};
        Object.keys(obj).forEach((key) => {
            if (obj[key] !== "") {
                notEmptyObj[key] = obj[key];
            }
        });

        let str = [];
        for (let p in notEmptyObj)
            if (notEmptyObj.hasOwnProperty(p)) {
                str.push(
                    encodeURIComponent(p) +
                        "=" +
                        encodeURIComponent(notEmptyObj[p])
                );
            }
        return str.join("&");
    }

    private handleError<T>(operation = "/", result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error.status); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            if (error.status === 498)
                window.location.href = "/auth/login?sessionExpired=true";
            return of(error as T);
        };
    }
}
