<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
	<div class="login__container">
		<!--begin::Aside-->
		<div class="login__image-side">
			<div class="kt-grid__item">
				<a href="javascript:;" class="kt-login__logo">
					<img src="../../../../assets/media/logos/logo.svg" width="80px" alt="">
				</a>
			</div>
			<div class="login__info-text">
				<div class="kt-grid__item kt-grid__item--middle">
					<h3 class="kt-login__title">Welcome to The SMBX</h3>
					<h4 class="kt-login__subtitle">The ultimate Bootstrap &amp; Angular 8 admin theme framework for next generation web apps.</h4>
				</div>
			</div>
			<div class="kt-grid__item">
				<div class="login__info">
					<div class="login__copyright">
						© {{today|date:'yyyy'}} SMBX LLC
					</div>
					<div class="login__menu">
						<a href="javascript:;" class="login__link">Privacy</a>
						<a href="javascript:;" class="login__link">Legal</a>
						<a href="javascript:;" class="login__link">Contact</a>
					</div>
				</div>
			</div>
			<div class="login__img"></div>
		</div>
		<!--begin::Aside-->

		<!--begin::Content-->
		<div class="login__form-side" style="z-index: 4;">
			<router-outlet></router-outlet>
		</div>
		<!--end::Content-->
	</div>
</div>
