<!--begin::Body-->
<div class="kt-login__body">
	<!--begin::Signin-->
	<div class="login__form">
		<div class="login__title">
			<h3>Sign In</h3>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<!--begin::Form-->
		<form class="kt-form" [formGroup]="loginForm" autocomplete="off">
			<div class="form-group">
				<mat-form-field>
					<mat-label>Username</mat-label>
					<input matInput type="text" placeholder="Username" formControlName="username" autocomplete="off"/>
					<mat-error *ngIf="isControlHasError('username','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('username','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off"/>
					<mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<!--begin::Action-->
			<div class="kt-login__actions">
				<button (click)="submit()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary">{{ 'AUTH.LOGIN.BUTTON' | translate }}</button>
			</div>
			<!--end::Action-->
		</form>
		<!--end::Form-->



	</div>
	<!--end::Signin-->
</div>
<!--end::Body-->
