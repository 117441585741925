import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {
    username: string;
    avatar: string;
    id: number;
    email: string;
    password: string;
    fullname: string;
    roles: any;
    accessToken: any;

    clear(): void {
        this.username = '';
        this.avatar = '';
    }
}
