<div class="backdrop" [hidden]="!loading">
    <div class="loader-wrapper">
        <div class="loader-points">
            <div class="loader-point">
            <div class="point point-1">S</div>
            <div class="point-shadow point-shadow-1"></div>
            </div>
            <div class="loader-point">
            <div class="point point-2">M</div>
            <div class="point-shadow point-shadow-2"></div>
            </div>
            <div class="loader-point">
            <div class="point point-3">B</div>
            <div class="point-shadow point-shadow-3"></div>
            </div>
            <div class="loader-point">
            <div class="point point-4">x</div>
            <div class="point-shadow point-shadow-4"></div>
            </div>
        </div>
    </div>
</div>