import { Component, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})

export class LoadingComponent {

    public loading: boolean = false;
    constructor(
        private cdr: ChangeDetectorRef,
        private loadingService: LoadingService
    ) {}

    ngOnInit(): void {
        this.loadingService.isLoading.subscribe((v) => {
            this.loading = v;
            this.cdr.markForCheck();
        });
    }

}