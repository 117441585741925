// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Components
import { BaseComponent } from "./views/theme/base/base.component";
import { ErrorPageComponent } from "./views/theme/content/error-page/error-page.component";
// Auth
import { AuthGuard } from "./core/auth";
import { EnvResolver } from "./core/resolvers/env.resolver";

const routes: Routes = [
    {
        path: "",
        resolve: [EnvResolver],
        children: [
            {
                path: "auth",
                loadChildren: () =>
                    import("./views/pages/auth/auth.module").then(
                        (m) => m.AuthModule
                    ),
            },
            {
                path: "resetpassword",
                loadChildren: () =>
                    import(
                        "./views/pages/reset-password/reset-password.module"
                    ).then((m) => m.ResetPasswordModule),
            },
            {
                path: "",
                component: BaseComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: "dashboard",
                        loadChildren: () =>
                            import(
                                "./views/pages/dashboard/dashboard.module"
                            ).then((m) => m.DashboardModule),
                    },
                    {
                        path: "companies",
                        loadChildren: () =>
                            import(
                                "./views/pages/companies/companies.module"
                            ).then((m) => m.CompaniesModule),
                    },
                    // {
                    // 	path: 'issuer-onboardings',
                    // 	loadChildren: () => import('./views/pages/companies/in-onboarding.module').then(m => m.InOnboardingModule),
                    // },
                    {
                        path: "issuer-onboardings/:id",
                        loadChildren: () =>
                            import(
                                "./views/pages/companies/in-onboarding.module"
                            ).then((m) => m.InOnboardingModule),
                    },
                    {
                        path: "companies/:sno/security",
                        loadChildren: () =>
                            import(
                                "./views/pages/securities/securities.module"
                            ).then((m) => m.SecuritiesModule),
                    },
                    {
                        path: "industries",
                        loadChildren: () =>
                            import(
                                "./views/pages/industries/industries.module"
                            ).then((m) => m.IndustriesModule),
                    },
                    {
                        path: "users",
                        loadChildren: () =>
                            import("./views/pages/users/users.module").then(
                                (m) => m.UsersModule
                            ),
                    },
                    {
                        path: "transactions",
                        loadChildren: () =>
                            import(
                                "./views/pages/transactions/transactions.module"
                            ).then((m) => m.TransactionsModule),
                    },
                    {
                        path: "monthly-payments",
                        loadChildren: () =>
                            import(
                                "./views/pages/monthly-payments/monthly-payments.module"
                            ).then((m) => m.MonthlyPaymentsModule),
                    },
                    {
                        path: "withdrawals",
                        loadChildren: () =>
                            import(
                                "./views/pages/withdrawals/withdrawals.module"
                            ).then((m) => m.WithdrawalsModule),
                    },
                    {
                        path: "referral-bonus",
                        loadChildren: () =>
                            import(
                                "./views/pages/referral-bonus/referral-bonus.module"
                            ).then((m) => m.ReferralBonusModule),
                    },
                    {
                        path: "offering",
                        loadChildren: () =>
                            import(
                                "./views/pages/offering/offering.module"
                            ).then((m) => m.OfferingModule),
                    },
                    {
                        path: "error/403",
                        component: ErrorPageComponent,
                        data: {
                            type: "error-v6",
                            code: 403,
                            title: "403... Access forbidden",
                            desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
                        },
                    },
                    { path: "error/:type", component: ErrorPageComponent },
                    { path: "", redirectTo: "dashboard", pathMatch: "full" },
                    { path: "**", redirectTo: "dashboard", pathMatch: "full" },
                ],
            },

            { path: "**", redirectTo: "error/403", pathMatch: "full" },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
