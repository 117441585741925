<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
	<div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
		<div class="kt-footer__copyright">
			{{today | date:'yyyy'}}&nbsp;&copy;&nbsp;<a href="https://thesmbx.com/" target="_blank" class="kt-link">SMBX LLC</a>
		</div>
		<div class="kt-footer__copyright">
			SMBX Admin Panel v{{appVersion}}
		</div>
	</div>
</div>
<!-- end:: Footer -->
