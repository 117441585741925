<div ngbDropdown placement="bottom-right" *ngIf="user$ | async as _user" class="kt-header__topbar-item kt-header__topbar-item--user h-100">
	<div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<div class="kt-header__topbar-user" [ngClass]="{'kt-header__topbar-icon': icon}">
			<span class="kt-hidden-mobile text-muted font-size-base d-none d-md-inline mr-1" *ngIf="greeting">Hi,</span>
			<span class="kt-hidden-mobile text-dark-50 font-weight-bold font-size-base d-none d-md-inline mr-3" *ngIf="greeting">{{_user.username}}</span>
			<i *ngIf="icon" class="flaticon2-user-outline-symbol"></i>
			<div class="symbol symbol-35 symbol-light-success">
				<span class="symbol-label font-size-h5 font-weight-bold">{{_user.username|firstLetter}}</span>
			</div>
		</div>
	</div>
	<div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
		<!--begin: Head -->
		<div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
			<div class="kt-user-card__avatar">
				<img alt="Avatar" src="{{_user.avatar}}"/>
				<span [hidden]="true" class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success color-black">
					{{_user.username}}
				</span>
			</div>
			<div class="kt-user-card__name color-black">
				{{_user.username}}
			</div>
		</div>
		<!--end: Head -->

		<!--begin: Navigation -->
		<div class="kt-notification">
			<!-- <a routerLink="profile" href="javascript:;" class="kt-notification__item">
				<div class="kt-notification__item-icon">
					<i class="flaticon2-calendar-3 kt-font-success"></i>
				</div>
				<div class="kt-notification__item-details">
					<div class="kt-notification__item-title kt-font-bold">
						My Profile
					</div>
					<div class="kt-notification__item-time">
						Account settings and more
					</div>
				</div>
			</a> -->

			<div class="kt-notification__custom">
				<a href="javascript:;" (click)="logout()" class="btn btn-outline-brand btn-upper btn-sm btn-bold">Sign Out</a>
			</div>
		</div>
		<!--end: Navigation -->

		<!--begin: Navigation(alternative) -->
		<ul class="kt-nav kt-margin-b-10 kt-hidden">
			<li class="kt-nav__item">
				<a routerLink="profile" class="kt-nav__link">
					<span class="kt-nav__link-icon"><i class="flaticon2-calendar-3"></i></span>
					<span class="kt-nav__link-text">My Profile</span>
				</a>
			</li>
			<li class="kt-nav__item">
				<a routerLink="profile" class="kt-nav__link">
					<span class="kt-nav__link-icon"><i class="flaticon2-browser-2"></i></span>
					<span class="kt-nav__link-text">My Tasks</span>
				</a>
			</li>
			<li class="kt-nav__item">
				<a routerLink="profile" class="kt-nav__link">
					<span class="kt-nav__link-icon"><i class="flaticon2-mail"></i></span>
					<span class="kt-nav__link-text">Messages</span>
				</a>
			</li>
			<li class="kt-nav__item">
				<a routerLink="profile" class="kt-nav__link">
					<span class="kt-nav__link-icon"><i class="flaticon2-gear"></i></span>
					<span class="kt-nav__link-text">Settings</span>
				</a>
			</li>
			<li class="kt-nav__item kt-nav__item--custom kt-margin-t-15">
				<a (click)="logout()" class="btn btn-outline-brand btn-upper btn-sm btn-bold">Sign Out</a>
			</li>
		</ul>
		<!--end: Navigation(alternative) -->
	</div>
</div>
