<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
	<!--end: Quick panel toggler -->
	<!--begin: Language bar -->
	<!-- <kt-language-selector></kt-language-selector> -->
	<!--end: Language bar -->
	<!--begin: User bar -->
	<kt-user-profile></kt-user-profile>
	<!--end: User bar -->
</div>
<!-- end:: Header Topbar -->
