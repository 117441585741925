import { Injectable } from "@angular/core";
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiService } from '../../../../api.service';
import { routes } from '../../../../api.config.js';



@Injectable()
export class AuthenticationService {
    constructor(
        private api: ApiService
    ) {
    }
    login(username: string, password: string) {
        let form = new FormData();
        form.append("username", username);
        form.append("password", password);

        return this.api.post(routes.login, form).pipe(
            tap(user => {
                if (user && user.data) {
                    localStorage.setItem('token', user.data.access_token);
                    localStorage.setItem('role', user.data.role);
                    if (user.data.username) {
                        localStorage.setItem('username', user.data.username);
                    }
                    if (user.data.avatar) {
                        localStorage.setItem('avatar', user.data.avatar);
                    }

                    if (user.data.role === 'issuer') {
                        localStorage.setItem('offering_sno', user.data.offering_sno[0]);
                    }
                }
            }),

        );
    }
    logout() {
        // remove user from local storage to log user out
        return this.api.post(routes.logout, new FormData()).pipe(
            tap(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                localStorage.removeItem('offering_sno');
                localStorage.removeItem('avatar');
                localStorage.removeItem('username')
            })
        )

        
    }
}