export class MenuConfig {
    public defaults: any = {
        header: {
            self: {},
            items: [
                {
                    title: "Dashboards",
                    root: true,
                    alignment: "left",
                    page: "/dashboard",
                    translate: "MENU.DASHBOARD",
                },
                {
                    title: "Companies",
                    root: true,
                    page: "/companies",
                    translate: "MENU.COMPANIES",
                },
            ],
        },
        aside: {
            self: {},
            items: [
                {
                    title: "Dashboard",
                    root: true,
                    icon: "flaticon2-analytics-2",
                    page: "/dashboard",
                    translate: "MENU.DASHBOARD",
                    bullet: "dot",
                },
                {
                    title: "Companies",
                    root: true,
                    page: "/companies",
                    icon: "flaticon2-architecture-and-city",
                    translate: "MENU.COMPANIES",
                    bullet: "dot",
                },
                {
                    title: "Industries",
                    root: true,
                    page: "/industries",
                    icon: "flaticon-map",
                    translate: "MENU.INDUSTRIES",
                    bullet: "dot",
                },
                {
                    title: "Users",
                    root: true,
                    page: "/users",
                    icon: "flaticon-users",
                    translate: "MENU.USERS",
                    bullet: "dot",
                },
                {
                    title: "Transactions",
                    root: true,
                    page: "/transactions",
                    translate: "MENU.TRANSACTIONS",
                    icon: "flaticon-price-tag",
                    bullet: "dot",
                },
                {
                    title: "Monthly payments",
                    root: true,
                    page: "/monthly-payments",
                    translate: "MENU.MONTHLY_PAYMENTS",
                    icon: "flaticon-calendar-with-a-clock-time-tools",
                    bullet: "dot",
                },
                {
                    title: "Withdrawals",
                    root: true,
                    page: "/withdrawals",
                    translate: "MENU.WITHDRAWALS",
                    icon: "flaticon-piggy-bank",
                    bullet: "dot",
                },
                {
                    title: "Withdrawal of Referral Bonus",
                    root: true,
                    page: "/referral-bonus",
                    translate: "MENU.REFERRAL_BONUS",
                    icon: "flaticon2-percentage",
                    bullet: "dot",
                },
            ],
        },
    };

    public get configs(): any {
        return this.defaults;
    }
}
