import { NgModule } from '@angular/core';
import { ImageUploadComponent } from './image-upload.component';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@NgModule({

    declarations: [
        ImageUploadComponent,
        LoadingComponent
    ],
    exports: [ ImageUploadComponent, LoadingComponent],
    imports: [ CommonModule, MatIconModule, MatButtonModule, ]

})

export class SharedModule {}