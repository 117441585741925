<!-- begin:: Content Head -->
<div class="subheader py-2 py-lg-4 subheader-solid" [ngClass]="{'kt-container--clear': clear}" [hidden]="subheaderService.disabled$ | async" id="kt_subheader">
	<div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
		<div class="kt-subheader__main">
			<h5 class="text-dark my-2 mr-5">{{title}}</h5>
			<span class="kt-subheader__separator kt-subheader__separator--v"></span>
			<!--<h4 *ngIf="desc" class="kt-subheader__desc">{{desc}}</h4>-->

		</div>
	</div>
</div>
<!-- end:: Content Head -->
